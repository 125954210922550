import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import Layout from "../components/Layout"
import BackImage from "../images/backgroundBlack.png"
import Image from "gatsby-image"
import OurMenu from "../components/OurMenu"
import MenuDetails from "../components/MenuDetails"
import SEO from "../components/SEO"

const StyledWrap = styled.div`
  width:100%;
  position:relative;
`
const StyledBack = styled.div`
  position:absolute;
  background:url(${BackImage});
  width:100%;
  height:100%;
  top:0;
  left:0;
  z-index:0;
`
const StyledContents = styled.div`
  z-index:1;
  position:relative;
  width:100%;
  margin:0 auto;
  padding-bottom:50px;
`

const StyledTop = styled.div`
  position:relative;
  display:flex;
  flex-flow:row nowrap;
  align-items:center;
  justify-content:center;
  width:100%;
  height:150px;
  .gatsby-image-wrapper{
    position:absolute!important;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index:-1;
    object-fit:cover;
    &:after{
      display:block;
      content:"";
      width:100%;
      height:100%;
      top:0;
      left:0;
      background:rgba(0,0,0,.6);
      z-index:0;
      position:absolute;
    }
  }
  .h2{
    position:relative;
  }

`

const StyledLead = styled.div`
  width:90%;
  margin:30px auto;
    ${() => media.tablet(css`
  max-width:800px;
  padding:50px 0;
  `
)}

`

const ForFirst = () => {
  const data = useStaticQuery(query)

  const jsonld = [
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${data.seo.pagesSeo[0].pageUrl}`,
          "name": `${data.seo.pagesSeo[0].pageTitle}`,
          "image": `${data.seo.pagesSeo[0].pageMedia.absolutePath}`
        }
      }, {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${data.seo.pagesSeo[3].pageUrl}`,
          "name": `${data.seo.pagesSeo[3].pageTitle}`,
          "image": `${data.seo.pagesSeo[3].pageMedia.absolutePath}`
        }
      }]
    }
  ]

  return (
    <Layout>
      <SEO
        title={data.seo.pagesSeo[3].pageTitle}
        description={data.seo.pagesSeo[3].pageDescript}
        jsonld={jsonld}
        pageUrl={data.seo.pagesSeo[3].pageUrl}
        type='article'
        imageUrl={data.seo.pagesSeo[3].pageMedia.absolutePath}
      />
      <StyledWrap>
        <StyledBack />
        <StyledContents>
          <StyledTop>
            <h2>{data.zoo.pageTitle}</h2>
            <Image fluid={data.zoo.pageHeaderMedia.childImageSharp.fluid} alt={data.zoo.pageImageAlt} />
          </StyledTop>
          <StyledLead>
            <p dangerouslySetInnerHTML={{ __html: data.zoo.dsihSubtitles }} />
          </StyledLead>
          <OurMenu />
          <MenuDetails />
        </StyledContents>
      </StyledWrap>

    </Layout>
  )
}

export const query = graphql`
  {
    zoo: strapiMenus {
      pageTitle
      dsihSubtitles
      pageImageALt
      pageHeaderMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    seo: strapiSeo {
      pagesSeo {
        pageDescript
        id
        pageTitle
        pageUrl
        pageMedia {
          absolutePath
        }
      }
      personName
      organizeName
      logoImage {
        absolutePath
      }
    }
  }
`

export default ForFirst

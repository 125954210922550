import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"
import { Link as ScrollLink } from "react-scroll"
import { IconContext } from "react-icons"
import { BiDownArrow } from "react-icons/bi"

import { media } from "../styles/media"

const StyledWrap = styled.div`
  ${() =>
    media.tablet(css`
      max-width: 600px;
    `)}

  width:80%;
  margin: 20px auto;
  padding: 30px 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
  a {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.62em;
    &:last-child {
      margin-bottom: 0;
    }
    svg {
      margin-left: 0.62em;
    }
  }
`

const IconDown = () => (
  <IconContext.Provider value={{ color: "white", size: "1em" }}>
    <BiDownArrow />
  </IconContext.Provider>
)

const scrollAtt = {
  activeClass: "active",
  spy: true,
  smooth: true,
  offset: -80,
  duration: 500,
}

const OurMenu = () => {
  const { menus } = useStaticQuery(query)
  return (
    <StyledWrap>
      {menus.linkList.map((menu, index) => (
        <ScrollLink to={menu.linkUrl} {...scrollAtt}>
          <span key={index}>{menu.linkTxt}</span>
          <IconDown />
        </ScrollLink>
      ))}
    </StyledWrap>
  )
}

export const query = graphql`
  {
    menus: strapiMenus {
      linkList {
        linkTxt
        linkUrl
      }
    }
  }
`

export default OurMenu

import React from "react"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { Element } from "react-scroll"

const StyledWrap = styled.div`

`
const StyledFirst = styled.div`
  ${() => media.tablet(css`
  max-width:700px;
  `
)}

  width:90%;
  margin:50px auto;
  h2{
    font-weight:bold;
    margin-bottom:1.62em;
    font-size:1.32em;
  }
  .menues{
    margin:50px 0 80px;
      ${() => media.tablet(css`
      display:flex;
      flex-flow:row wrap;
      align-items:flex-start;
      justify-content:space-between;
      h3{
        width:100%!important;
      }
      .whois{
        width:100%!important;
        margin-bottom:2em;
      }
      .menuCon{
        width:45%!important;
      }
      .gatsby-image-wrapper{
        width:50%;
      }
  `
)}

    h3{
      width:100%;
      font-size:1.32em;
    }
    .whois{
      width:100%;
      text-align:right;
      color:#dadada;
      font-size:.8em;
    }
    .menuCon{
      width:100%;
      padding:1.62em 0 1.62em;
      font-size:.9em;
      line-height:1.62em;
    }
    .prices{
      display:flex;
      flex-flow:row nowrap;
      align-items:flex-end;
      width:100%;
      justify-content:flex-end;
      margin-top:.62em;
      p{
        font-size:1.12em;
        margin-left:.62em;
        span{
          font-size:.6em;
        }
      }
    }

  }
`


const MenuDetails = () => {
  const data = useStaticQuery(query)
  return (
    <StyledWrap>
      <StyledFirst>
        <Element name="medicalaroma" />
        {data.zoo.panda.map((bamboo, index) => (
          <div className="menues" key={index}>
            <h3>{bamboo.menuTiltle}</h3>
            <p className="whois">{bamboo.whoIs}</p>
            <p className="menuCon" dangerouslySetInnerHTML={{ __html: bamboo.dsihContents }} />
            <Image fluid={bamboo.menuMedia.childImageSharp.fluid} alt={bamboo.menuMediaAlt} />
            <div className="prices">
              <p>{bamboo.menuMinutes}<span>分</span></p>
              <p>{bamboo.menuPrice}<span>円</span></p>
            </div>
          </div>
        ))}

        {data.zoo.horse.map((race, index) => (
          <>
            <Element name={race.elementAnchor} />
            <div className="menues" key={index}>
              <h3>{race.menuTitle}</h3>
              <p className="whois">{race.whoIs}</p>
              <p className="menuCon" dangerouslySetInnerHTML={{ __html: race.dsihContents }} />
              <Image fluid={race.menuImg.childImageSharp.fluid} alt={race.menuImgAlt} />
              {race.priceMenu.map((goals, index) => (
                <div className="prices" key={index}>
                  <p>{goals.menuTitle}</p>
                  <p>{goals.meniuMinutes}<span>分</span></p>
                  <p>{goals.memuPrice}<span>円</span></p>
                </div>
              ))}
            </div>
          </>
        ))}
        <h3>その他Option</h3>
        {data.zoo.zebra.map((yellow, index) => (
          <>
            <div className="menues" key={index}>
              <h5>{yellow.menuName}</h5>
              <p className="menuCon" dangerouslySetInnerHTML={{ __html: yellow.menuContents }} />
              <div className="prices">
                {yellow.menuMinutes &&
                  <p>{yellow.menuMinutes}<span>分</span></p>
                }
                {yellow.menuPrice &&
                  <p>{yellow.menuPrice}<span>円</span></p>
                }
              </div>
            </div>
          </>
        ))}
      </StyledFirst>
    </StyledWrap>
  )
}

export const query = graphql`
  {
    zoo:strapiMenus {
      panda:menusListWithImgAroma {
        id
        menuTiltle
        whoIs
        dsihContents
        menuMediaAlt
        menuMinutes
        menuPrice
        menuMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      horse:linkMenuMisc {
        menuTitle
        whoIs
        elementAnchor
        dsihContents
        id
        menuImgAlt
        menuImg {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        priceMenu {
          memuPrice
          meniuMinutes
          menuTitle
        }
      }
      zebra:linkMenus {
        menuContents
        menuMinutes
        menuName
        menuPrice
      }
    }
  }
`

export default MenuDetails
